define('app/tile-touch-response',['jquery', 'app/effects'], function($, effects) {

  var thisEl = $('.js-tile');

  var exports = {
    el: thisEl,

    init: function() {

      if (Modernizr.touch && Modernizr.csstransitions) {

        thisEl.on({
          click: function(e) {
            e.preventDefault();

            $(this).addClass('clicked').one(effects.transitionEndEvent, function() {
              $(this).removeClass('clicked');
              window.location = $('.tile-link', this).attr('href');
            });
          },

          touchstart: function() {
            thisEl.removeClass('clicked');
          }
        });
      }
    }
  };

    return exports.init();

});
