define('app/deepfocus',['require', 'konami', 'app/polyfills'], function(require) {

	var bodyId = document.body.id;

	var kurilianBobtail = new Konami(function() {
		require(['app/trail'], function(trail) {
			$('body').toggleClass('kurilian-bobtail');
			trail.init();
		});
	});

	if (bodyId === 'js-home-page' || bodyId === 'js-error-page') {
		require(['app/preloader', 'app/main-nav', 'app/panels'], function(preloader, mainNav, panels) {
      preloader.init();
			panels.init();
			mainNav.init();
		});
	}

	if (bodyId === 'js-error-page') {
		require(['app/trail'], function(trail) {
			trail.init();
		});
	}

	if (bodyId === 'js-press-page') {
		require(['app/load-more-button'], function(loadMoreButton) {
			loadMoreButton.init();
		});
	}

	if (bodyId === 'js-contact-page') {
		require(['app/contact-page'], function(contactPage) {
			contactPage.init();
		});
	}

	require(['app/nav-list-toggle'], function(navListToggle) {
		navListToggle.init();
	});

	require(['app/scroll-arrow'], function(scrollArrow) {
		if (scrollArrow.el.length > 0) {
			scrollArrow.init();
		}
	});

	require(['app/toggle-fixed', 'app/accordion', 'app/clients-link', 'app/tile-touch-response'], function(toggleFixed) {
		toggleFixed.init();
	});
});

