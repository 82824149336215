define('app/main-nav',[
	'jquery',
	'modernizr',
	'app/effects',
	'app/panels'],
	function($, Modernizr, effects, panels) {

	var thisEl = $('#js-main-header'),
			headerBackground = $('#js-background'),
      hamburgerButton = $('.js-patty'),
			windowHeight = window.innerHeight,
			transformProp = effects.transformProp,
			thisPanel,
			panelCount = panels.el.length,
			thisOffset,
			thisElHeight = thisEl.height(),
			props = {},
			isScrolling,
			scrollTimeout;

	var exports = {
		el: thisEl,

		init: function() {
			var self = this;

			if (Modernizr.prefixed('requestAnimationFrame', window) &&
					Modernizr.mq('screen and (min-width: 320px)')) {

				$(window).on('scroll', function() {
					if (!isScrolling) {
						isScrolling = window.requestAnimationFrame(self.changeColor);
					}

					if (scrollTimeout) {
						window.clearTimeout(scrollTimeout);
					}

					scrollTimeout = window.setTimeout(function() {
						isScrolling = false;
					}, 100);
				}).scroll();
			}

			return this;
		},

		changeColor: function() {
			windowHeight = window.innerHeight;
			for (var i = panelCount - 1; i >= 0; i--) {

				thisPanel = panels.el.eq(i);
				thisOffset = window.pageYOffset - thisPanel.offset().top;

				if (thisOffset <= thisElHeight || windowHeight - thisOffset > thisElHeight) {
					if (thisOffset >= 0) {
						props = {
							color: thisPanel.css('color'),
							backgroundColor: thisPanel.css('backgroundColor'),
							backgroundImage: thisPanel.children('.stretch').css('backgroundImage'),
							transform: -thisOffset
						};
					} else {
						props = {
							color: thisPanel.next().css('color'),
							backgroundColor: 'transparent',
							backgroundImage: ''
						};
					}
				}

        $('.home-link').toggleClass('is-visible', window.pageYOffset >= windowHeight);
			}

			thisEl[0].style.color = props.color;
      hamburgerButton[0].style.backgroundColor = props.color;

			headerBackground[0].style.backgroundImage = props.backgroundImage;
      headerBackground.eq(0).css({backgroundAttachment: "fixed"});

			headerBackground[0].style.backgroundColor = props.backgroundColor;

			if (isScrolling) {
				isScrolling = window.requestAnimationFrame(exports.changeColor);
			}
		}
	};

	return exports;
});

