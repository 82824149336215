define('app/nudge',['jquery', 'app/effects', 'app/main-nav'], function($, Effects, mainNav) {

	var thisEl = $('<div>').addClass('js-nudge nudge');

	var exports = {
		el: thisEl,

		slideTo: function(el, offset) {
      offset = offset || 0;
			this.el.prependTo(el);
			Effects.scrollToTop(this.el, mainNav.el.height() + offset);
		},

		init: function() {
			$('body').append(this.el);
			return this;
		}
	};

	return exports.init();
});
