define('app/clients-link',['jquery', 'app/nudge'], function($, nudge) {

  var thisEl = $('.js-clients');

  var exports = {
    el: thisEl,

    init: function() {
      $('.js-clients').on('click', function(e) {
        e.preventDefault();
        nudge.slideTo('.clients-section');
      });
    }
  };

    return exports.init();

});
