define('app/polyfills',[
	'require',
	'modernizr',
	'polyfill.classlist',
	'polyfill.requestanimationframe',
	'viewport-units-buggyfill'
], function(require, Modernizr) {

	if (!Modernizr.classList) {
		require('polyfill.classlist');
	}

	if (!Modernizr.prefixed('requestanimationframe', window)) {
		require('polyfill.requestanimationframe');
	}

	require('viewport-units-buggyfill').init();
});
