define('app/toggle-fixed',['jquery', 'modernizr'], function($, Modernizr) {

	var thisEl = $('.js-is-fixed'),
			lock, lockTop, lockAtBottom, elHeight, delta, lockOuterHeight, cacheTop,
			offsetParentTop;

	var exports = {
		el: thisEl,

		init: function() {
			// Check to see if requestAnimationFrame is not supported
			// then, check to see if it's a mobile viewport
			this.attachScrollEvent();
			return this;
		},

		/**
		 * Attach the locking toggle to the window's scroll event.
		 */
		attachScrollEvent: function() {
			var self = this;

			$(window).on('scroll', function() {

				if(!Modernizr.touch) {
					self.toggleFixed(this.pageYOffset);
				}

			}).scroll();

			return this;
		},

		toggleFixed: function(y) {
			var thisToggleEl, thisLock;

			this.el.each(function() {
				thisToggleEl = $(this);
				thisLock = thisToggleEl.data('lock');

				if (thisLock) {
					lock = $('#' + thisLock);
					lockTop = lock.offset().top;
					lockOuterHeight = lock.outerHeight();
					elHeight = thisToggleEl.outerHeight();
					delta = lockTop + lock.outerHeight() - y;
					lockAtBottom = delta <= elHeight;
					cacheTop = thisToggleEl.offsetParent().offset().top !== 0 ? thisToggleEl.offsetParent().offset().top : cacheTop;

					thisToggleEl.toggleClass('is-fixed-to-bottom', lockAtBottom);

					thisToggleEl.css({
						top: lockAtBottom ? lockTop + lockOuterHeight -  elHeight - cacheTop : ''
					});
				}

				if (!thisToggleEl.hasClass('is-fixed')) {
					this.top = thisToggleEl.offset().top;
				}

				thisToggleEl.toggleClass('is-fixed', this.top - y <= 0);

			});
		}
	};

	return exports;
});
