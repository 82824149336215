define('app/scroll-arrow',['jquery', 'modernizr', 'app/panels', 'app/main-nav', 'app/nudge', 'app/effects'], function($, Modernizr, panels, mainNav, nudge, effects) {

	var thisEl = $('.js-scroll-arrow'),
			transformProp = effects.transformProp,
			windowHeight = window.innerHeight,
			navHeight = $('.main-nav').height(),
			thisElLength = thisEl.length,
      panelLength = $('.js-panel').length,
			yPos;

	var exports = {
		el: thisEl,

		init: function() {
			var self = this;

			thisElLength = this.el.length;

			this.el.on('click', function(e) {

				var nextPanel = panels.el.eq(panels.el.index($(this).closest(panels.el)) + 1);
				console.log(nextPanel);

				if (nextPanel.length <= 0) {
					nextPanel = panels.el.first();
				}

				nudge.slideTo(nextPanel, -mainNav.el.height());

				e.preventDefault();
			});

			window.requestAnimationFrame(self.adjustTransform);

			return this;
		},

		adjustTransform: function() {
			var currentEl;
			yPos = (window.pageYOffset / window.outerHeight) * 100;

			window.requestAnimationFrame(exports.adjustTransform);
		}
	};

	return exports;
});
