define('app/accordion',['jquery', 'app/effects', 'app/nudge'], function($, effects, nudge) {

  var thisEl = $('.js-accordion');

  var exports = {
    el: thisEl,

    init: function() {

      var self = this,
          containers = thisEl.find('accordion-container');

      $('.accordion-container').on('click', function() {
        var thisContainer = $(this),
            thisContent = thisContainer.children('.accordion-content');

        if ($('.accordion-container').hasClass('active')) {
          thisContent.removeClass('active');
        }
        thisContent.toggleClass('active');
        nudge.slideTo(thisContainer, 32);
        thisContainer.children('.icon').toggleClass('icon-down-arrow icon-close');
      });

      return this;
    }
  };

  return exports.init();

});
