define('app/load-more-button',['jquery', 'mustache'], function($, Mustache) {

    var thisEl = $('#js-load-more-button'),
        panelTemplate = $('#js-press-listing-template').html(),
        contentPanel = $('#content-panel'),
        filters = $('.js-filters a');

	var exports = {
		el: thisEl,

		init: function() {
			var self = this;

			this.el.on('click', function (e) {
			    var offset = contentPanel.children().length;
			    e.preventDefault();
			    self.updateList(offset);
			});

			filters.on('click', function (e) {
			    e.preventDefault();
			    var filter = $(this);

			    contentPanel.fadeOut('fast', function() {

			    	filters.removeClass('active');
			    	filter.toggleClass('active');
			    	self.updateList();

			    }).fadeIn();

		    });

			return this;
		},

		updateList: function(offset) {
		    var self = this,
		        filterEl = $('.js-filters a.active'),
		        filterId = null;
                if (filterEl.length > 0) {
                    filterId = filterEl.data('filter');
                }

		    offset = offset || 0;

		    $.ajax({
		        url: '/api/press/getall?offset=' + offset + (filterId ? '&filterId=' + filterId : ''),
				success: function(data) {
					self.populate(data, offset === 0);
				}
			});
		},

		populate: function(data, shouldReplace) {
		    var i = 0, out;
		    if (shouldReplace) {
		        contentPanel.children().remove();
		    }
		    for (; i < data.Results.length; i += 1) {
		        out = Mustache.render(panelTemplate, data.Results[i]);
                contentPanel.append(out);
			}
		    if (data.Results.length === 0 || contentPanel.children().length >= data.TotalResults) {
			    $('#js-load-more-button').hide();
			}
			else {
			    $('#js-load-more-button').show();
			}
		}
	};

	return exports;
});
