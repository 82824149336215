define('app/contact-page',['jquery'], function($) {

	var thisEl = $('#js-contact-page'),
			iOS = parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0,''])[1]).replace('undefined', '3_2').replace('_', '.').replace('_', '')) || false;

	var exports = {
		el: thisEl,

		init: function() {

			if (iOS < 8) {
				thisEl.addClass('ios-7');
			}

		}
	};

	return exports.init();
});
