/**
 * With love.
 * http://hakim.se/experiments/
 * http://twitter.com/hakimel
 */
define('app/trail',['modernizr'], function(Modernizr) {
	var SCREEN_WIDTH = 900,
			SCREEN_HEIGHT = 600,

			RADIUS = 30,

			RADIUS_SCALE = 1.5,
			RADIUS_SCALE_MIN = 1,
			RADIUS_SCALE_MAX = 4.5,

			// The number of particles that are used to generate the trail
			QUANTITY = 50,
			mouseX = (window.innerWidth - SCREEN_WIDTH),
			mouseY = (window.innerHeight - SCREEN_HEIGHT),
			canvas, context, particles = [];


	var exports = {
		init: function() {
			console.log('derp');
			canvas = document.createElement('canvas');
			canvas.id = 'world';
			document.body.appendChild(canvas);

			if (Modernizr.canvas) {
				if (canvas && canvas.getContext) {
					context = canvas.getContext('2d');

					// Register event listeners
					document.addEventListener('mousemove', documentMouseMoveHandler, false);
					canvas.addEventListener('touchstart', canvasTouchStartHandler, false);
					canvas.addEventListener('touchmove', canvasTouchMoveHandler, false);
					window.addEventListener('resize', windowResizeHandler, false);

					createParticles();

					windowResizeHandler();

					window.requestAnimationFrame(loop);
				}
			}
		}
	};

	function createParticles() {
		var particle;

		for (var i = QUANTITY - 1; i >= 0; i--) {
			particles.push({
				position: { x: mouseX, y: mouseY },
				shift: { x: mouseX, y: mouseY },
				size: 1,
				angle: 0,
				speed: 0.01 + Math.random() * 0.04,
				targetSize: 1,
				fillColor: '#' + (Math.random() * 0x404040 + 0xaaaaaa | 0).toString(16),
				orbit: RADIUS * 0.5 + (RADIUS * 0.5 * Math.random())
			});
		}
	}

	function documentMouseMoveHandler(event) {
		mouseX = event.clientX - (window.innerWidth - SCREEN_WIDTH) * 0.5;
		mouseY = event.clientY - (window.innerHeight - SCREEN_HEIGHT) * 0.5;
	}

	function canvasTouchStartHandler(event) {
		if(event.touches.length === 1) {
			event.preventDefault();

			mouseX = event.touches[0].pageX - (window.innerWidth - SCREEN_WIDTH) * 0.5;
			mouseY = event.touches[0].pageY - (window.innerHeight - SCREEN_HEIGHT) * 0.5;
		}
	}

	function canvasTouchMoveHandler(event) {
		if(event.touches.length === 1) {
			event.preventDefault();

			mouseX = event.touches[0].pageX - (window.innerWidth - SCREEN_WIDTH) * 0.5;
			mouseY = event.touches[0].pageY - (window.innerHeight - SCREEN_HEIGHT) * 0.5;
		}
	}

	function windowResizeHandler() {
		SCREEN_WIDTH = window.innerWidth;
		SCREEN_HEIGHT = window.innerHeight;

		canvas.width = SCREEN_WIDTH;
		canvas.height = SCREEN_HEIGHT;

		canvas.style.position = 'fixed';
		canvas.style.pointerEvents = 'none';
		canvas.style.left = (window.innerWidth - SCREEN_WIDTH) * 0.5 + 'px';
		canvas.style.top = (window.innerHeight - SCREEN_HEIGHT) * 0.5 + 'px';
	}

	function loop() {
		var particle, lp, minx, miny;

		RADIUS_SCALE -= ( RADIUS_SCALE - RADIUS_SCALE_MIN ) * (0.02);
		RADIUS_SCALE = Math.min( RADIUS_SCALE, RADIUS_SCALE_MAX );

		// Fade out the lines slowly by drawing a rectangle over the entire canvas
		context.clearRect(0, 0, context.canvas.width, context.canvas.height);

		for (var i = particles.length - 1; i >= 0; i--) {
			particle = particles[i];

			lp = { x: particle.position.x, y: particle.position.y };

			// Offset the angle to keep the spin going
			particle.angle += particle.speed;

			// Follow mouse with some lag
			particle.shift.x += ( mouseX - particle.shift.x) * (particle.speed);
			particle.shift.y += ( mouseY - particle.shift.y) * (particle.speed);

			// Apply position
			particle.position.x = particle.shift.x + Math.cos(i + particle.angle) * (particle.orbit*RADIUS_SCALE);
			particle.position.y = particle.shift.y + Math.sin(i + particle.angle) * (particle.orbit*RADIUS_SCALE);

			// Limit to screen bounds
			minx = SCREEN_WIDTH ^ ((particle.position.x ^ SCREEN_WIDTH) & -(particle.position.x < SCREEN_WIDTH));
			miny = SCREEN_HEIGHT ^ ((particle.position.y ^ SCREEN_HEIGHT) & -(particle.position.y < SCREEN_HEIGHT));

			particle.position.x = minx ^ ((minx ^ 0) & -(minx < 0));
			particle.position.y = miny ^ ((miny ^ 0) & -(miny < 0));

			particle.size += ( particle.targetSize - particle.size ) * 0.05;

			// If we're at the target size, set a new one. Think of it like a regular day at work.
			if( ~~( particle.size ) === ~~( particle.targetSize ) ) {
				particle.targetSize = 1 + Math.random() * 7;
			}

			context.beginPath();
			context.fillStyle = particle.fillColor;
			context.strokeStyle = particle.fillColor;
			context.lineWidth = particle.size;
			context.moveTo(lp.x, lp.y);
			context.lineTo(particle.position.x, particle.position.y);
			context.stroke();
			context.arc(particle.position.x, particle.position.y, particle.size/2, 0, Math.PI*2, true);
			context.fill();
		}

		window.requestAnimationFrame(loop);
	}

	return exports;
});
