define('app/preloader',['jquery', 'app/panels', 'app/effects'], function($, panels, effects) {

  var thisEl = $('.js-preloader');

  var exports = {
    el: thisEl,

    init: function() {

      if (!thisEl) {
        return this;
      }

      panels.el.on('panelResize', function(e) {
        thisEl.addClass('is-fading');
      });

      if (effects.transitionEndEvent) {
        thisEl.on(effects.transitionEndEvent, function() {
          thisEl.removeClass('is-visible');
        });
      } else {
          thisEl.removeClass('is-visible');
      }

      return this;
    }
  };

  return exports;
});
