define('app/nav-list',['app/effects'], function(effects) {
	var thisEl = document.getElementById('js-main-nav');

	var exports = {
		el: thisEl,

		toggle: function() {
			effects.toggleExpand(this.el);

      $(function(){
        $('.nav-item').hide();
        $('.nav-item').each(function(i){
          $(this).delay((i++) * 50).fadeTo(100, 1);
        });
      });
		}
	};

	return exports;
});
