define('app/effects',['jquery', 'modernizr'], function($, Modernizr) {

  var mainNavHeight = $('#js-main-header').height();

	var exports = {

		transitionEndEvent: (function() {
			return {
				'WebkitTransition': 'webkitTransitionEnd',// Saf 6, Android Browser
				'MozTransition': 'transitionend',      // only for FF < 15
				'transition': 'transitionend'       // IE10, Opera, Chrome, FF 15+, Saf 7+
			}[Modernizr.prefixed('transition')];
		}()),

		transformProp: (function() {
			return {
				'WebkitTransform': 'webkitTransform',// Saf 6, Android Browser
				'MozTransform': 'Transform',      // only for FF < 15
				'transform': 'transform'       // IE10, Opera, Chrome, FF 15+, Saf 7+
			}[Modernizr.prefixed('transform')];
		}()),

		scrollToTop: function(el, extra) {
      $('html, body, document').stop().animate({
        scrollTop: (el) ? el.offset().top - (extra || 0) : 0
      }, 450);

			return this;
		},

		toggleExpand: function(el) {
			var maxHeight;
			$(el).toggleClass('is-expanded');
			return this;
		},

		expand: function(el) {
			el.addClass('is-expanded');
			return this;
		},

		collapse: function(el) {
			el.removeClass('is-expanded');
			return this;
		},

		toggleSqueezed: function(el) {
			el.toggleClass('is-squeezed');
			return this;
		},

		toggleActive: function(el) {
			el.toggleClass('is-active');
			return this;
		},

		deactivate: function(el) {
			el.removeClass('is-active');
			return this;
		}
	};

	return exports;
});
