define('app/panels',['jquery', 'modernizr', 'app/effects'], function($, Modernizr, effects) {

	var thisEl = $('.js-panel'),
			thisPanelHeadline = $('.panel-headline');

	var exports = {
		el: thisEl,

		init: function() {
			var self = this,
					panelEvent = (Modernizr.deviceorientation &&
							Modernizr.mq('screen and (max-width: 1024px)')) ?
							'orientationchange' : 'resize',
					resizeTimeout;

			$(window).on(panelEvent, function() {

				if (resizeTimeout) {
					window.clearTimeout(resizeTimeout);
				}

				resizeTimeout = window.setTimeout(function() {
					self.resize();
				}, 50);
			});

			this.resize();

			return this;
		},

		resize: function() {
			var windowHeight = window.innerHeight,
					i, headlineHeight;

			thisEl.each(function(index) {
				headlineHeight = $('.panel-headline', this).height() + 40;
				$(this).css({
					'height': windowHeight > headlineHeight ? windowHeight : headlineHeight
				});
			});

			thisEl.trigger('panelResize');
		}
	};

	return exports;
});
