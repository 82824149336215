define('app/nav-list-toggle',['jquery', 'app/nav-list'], function($, navList) {

	var thisEl = $('#js-nav-list-toggle');

	var exports = {
		el: thisEl,

		init: function() {
			var self = this;

			this.el.on('click', function(e) {
				navList.toggle();
			});

			return this;
		}
	};

	return exports;
});
