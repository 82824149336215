require.config({
	baseUrl: './assets/js/lib',
	paths: {
		'app': '../deepfocus',
		'jquery': 'jquery',
		'jquery.transit': 'jquery.transit',
		'konami': 'konami',
		'mustache': 'mustache',
		'polyfill.classlist': 'polyfill.classlist',
		'polyfill.requestanimationframe': 'polyfill.requestanimationframe',
		'viewport-units-buggyfill': 'viewport-units-buggyfill',
		'jquery.simpleweather': '//cdnjs.cloudflare.com/ajax/libs/jquery.simpleWeather/3.0.2/jquery.simpleWeather.min'
	},
	urlArgs: "bust=" +  (new Date()).getTime(),

	shim: {
		'jquery.transit': ['jquery'],
		'jquery.simpleweather': ['jquery'],
		'polyfill.classlist': ['modernizr']
	},

	waitSeconds: 15
});

define('modernizr', [],function () {
	"use strict";
	return window.Modernizr;
});

require(['app/deepfocus'], function(DeepFocus) {
	return DeepFocus;
});
define("main", function(){});

